/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSignal, Show } from "solid-js";

export function DebugDumper(props: {
  name?: string;
  data?: any;
  errors?: any;
  touched?: any;
}) {
  const [show, setShow] = createSignal(true);
  return (
    <>
      <div
        style={{
          "background-color": "black",
          opacity: show() ? 0.8 : 0.5,
          color: "white",
          "font-size": "12px",
          padding: "3px",
          margin: "3px 0",
          position: "fixed",
          bottom: "0",
          "z-index": "999999999",
          right: 0,
          height: show() ? "inherit" : "20px",
          overflow: "scroll",
          /* "pointer-events": "none", */
          "word-wrap": "break-word",
          "max-width": "280px",
          "max-height": "800px",
        }}
      >
        <Show when={!show()}>
          <button style={{ color: "red" }} onClick={() => setShow(true)}>
            {props.name ? `Debug ${props.name}` : "Debug form"}
          </button>
        </Show>
        <Show when={show()}>
          <button style={{ color: "red" }} onClick={() => setShow(false)}>
            Hide {props.name}
          </button>
          <pre>Data: {JSON.stringify(props.data, null, 2)}</pre>
          <Show when={props.errors}>
            <pre>
              Errors:
              {/* Prettify the errors and display them in HTML */}
              {JSON.stringify(props.errors, null, 2)}
            </pre>
          </Show>
          <Show when={props.touched}>
            <pre>Touched: {JSON.stringify(props.touched, null, 2)}</pre>S
          </Show>
        </Show>
      </div>
    </>
  );
}
